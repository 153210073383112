<script src="../../assets/js/DES.js"></script>
<template>

<!--  <div>-->
<!--    &lt;!&ndash; 首页轮播 begin &ndash;&gt;-->
<!--    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">-->
<!--      <van-swipe-item><img src="@/assets/images/DD.jpg" alt="" style="width:99.999%; overflow:hidden;" ></van-swipe-item>-->
<!--     &lt;!&ndash; <van-swipe-item><img src="@/assets/images/DD.jpg" alt="" style="width:99.999%; overflow:hidden;" ></van-swipe-item>-->
<!--      <van-swipe-item><img src="@/assets/images/DD.jpg" alt="" style="width:99.999%; overflow:hidden;" ></van-swipe-item>&ndash;&gt;-->
<!--    </van-swipe>-->



<!--    &lt;!&ndash; 数据统计END&ndash;&gt;-->
<!--    <div class="aui-flex aui-flex-mar">-->
<!--      <div class="aui-flex-box">-->
<!--        <h2>数据统计</h2>-->
<!--      </div>-->

<!--    </div>-->
<!--    <div class="aui-palace clearfix">-->
<!--      <router-link href="javascript:;" class="aui-palace-grid" to="gcAdd">-->
<!--        <div class="aui-palace-grid-icon">-->
<!--          <img src="../../assets/images/gcgl.jpg" alt="">-->
<!--        </div>-->
<!--        <div class="aui-palace-grid-text">-->
<!--          <h2>考勤</h2>-->
<!--        </div>-->
<!--      </router-link>-->
<!--      <router-link href="javascript:;" class="aui-palace-grid" to="leaveAdd">-->
<!--        <div class="aui-palace-grid-icon">-->
<!--          <img src="../../assets/images/qjsq.jpg" alt="">-->
<!--        </div>-->
<!--        <div class="aui-palace-grid-text">-->
<!--          <h2>周任务</h2>-->
<!--        </div>-->
<!--      </router-link>-->
<!--    </div>-->
<!--    <div class="divHeight"></div>-->
    <!-- 数据统计END-->

  <div>
    <div class="main">
      <div class="top">
        <img src="@/assets/images/DD.jpg" />
      </div>
      <div class="list_content">
        <div class="d" style="height:60px; line-height:60px;">
          <div class="l">你好,{{uname}}</div>
          <div class="r">
            <span style="  font-size: 15px;font-weight: bold">&nbsp;</span>
            <span></span>
            <img
                src=""
                width="26"
                style="vertical-align:middle;margin-right:12px"
            />
          </div>
        </div>
        <div class="d">
          <div class="ico" @click="sm" >

              <div>
                <img src="../../assets/images/faxian.png" class="img2" />
              </div>
              <div>
                <font>我发现</font>
                <br />用户投诉入口
              </div>

          </div>
          <div class="ico">
            <router-link :to="'/shopList'">
              <div>
                <img src="../../assets/images/Shop.png" class="img3" />
              </div>
              <div>
                <font>我的店</font>
                <br />商家店铺入口
              </div>
            </router-link>
          </div>
          <div class="ico">
            <router-link :to="'/yhtsList'">
              <div>
                <img src="../../assets/images/canyu.png" class="img4" />
              </div>
              <div>
                <font>我参与</font>
                <br />用户历史投诉
              </div>
            </router-link>
          </div>
<!--          <div class="ico">-->
<!--            <router-link :to="'/index'">-->
<!--              <div>-->
<!--                <img src="../../assets/images/gcgl.jpg" class="img4" />-->
<!--              </div>-->
<!--              <div>-->
<!--                <font>扫黑除恶</font>-->
<!--                <br />举报入口-->
<!--              </div>-->
<!--            </router-link>-->
<!--          </div>-->
        </div>
      </div>
      <div style="height:80px" id="div_white">&nbsp;&nbsp;&nbsp;</div>
      <div
          id="div_foot"
          border="0"
          style="position: absolute; bottom: 55px;text-align: center;width:100%"
          cellpadding="0"
          cellspacing="0"
          height="45px"
      >
        <div>
          本服务由浙江政务服务网、余姚市综合执法局（城管局）提供
          <br />
          <div @click="CallTel">服务咨询热线：0574-62702648</div>
        </div>
      </div>
    </div>
    <!-- <div style="height:300px;">&nbsp;撑位置可删除</div> -->
  </div>




<!--  </div>-->

</template>


<script>

import {Dialog, Toast} from "vant";
import Emas from "@/assets/js/Emas";
export default {
  name: "index",
  data() {
    return {
      openid: '',
      token:'',
      RecipientList: [],
      BpmList: [],//待办事项
      uname:'',
      result: '', // 扫码结果信息
      error: '' ,// 错误信息
      ewm:'',//二维码中获取的店铺ID
    }
  },
  created() {
    this.openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    if (resname.length > 1) {
      this.uname = resname.replace(resname.substring(1, 2), "*");
    }
    Emas.getLocationMsg(this.openid,resname);
    // this.token = sessionStorage.getItem("token");
    // this.fetchMessageData()
    // let Staff_Name = sessionStorage.getItem("Staff_Name");
    // let User_ID = sessionStorage.getItem("User_ID");
    // this.fetchData()
  },

  methods: {
    sm(){
      //调用扫码方法
      ZWJSBridge.onReady(() => {
        ZWJSBridge.scan({
          type: "qrCode"
        }).then((data) => {
           //data =>{"text" : "http://mqsb.yy.gov.cn/WX_GZH/Check_WX.aspx?ID=5bf4fc83a7c60caf"}
          // alert(data.text)
          //扫码方法中执行以下代码
          //var ewm='http://mqsb.yy.gov.cn/WX_GZH/Check_WX.aspx?ID=5bf4fc83a7c60caf';
          var ewm=data.text;
          console.log(ewm);
          var a=ewm.split('=');
          if (a[0]=="http://mqsb.yy.gov.cn/WX_GZH/Check_WX.aspx?ID"){
            this.ewm=a[1];
            console.log(this.ewm);
            // alert(this.ewm)
            this.$router.push(
                {
                  path: '/yhts',
                  query: {
                    ewm: this.ewm
                  }
                })
          }else {
            Toast('请扫描门前三包店铺二维码');
          }
        }).catch((error) => {
          if(error.errorCode==3){
            Toast('请扫描支付宝专属二维码进入本应用');
          }else if(error.errorCode==1001){
            Toast('没有摄像头或摄像头不可用');
          }else if (error.errorCode==1002){
            Toast('没有拍照权限');
          }else if (error.errorCode==1003){
            Toast('没有图片库权限');
          }
        })
      })
     },

    CallTel() {
      Emas.callPhone("0574-62702648");
    },

  },
}
</script>

<style scoped>

@import "../../assets/css/base.css";
@import "../../assets/css/home.css";
@import "../../assets/css/icon.css";
@import "../../assets/css/swiper.min.css";
@import '../../assets/css/Hu.css';
.list_content .d .ico {
  width: 44%;
  float: left;
  text-align: center;
  margin-left: 0%;
  height: 50px;
  line-height: 24px;
  border-bottom: 0px #ff0000 dotted;
  margin-left: 4%;
  margin-bottom: 15px;
  margin-top: 15px;
}
.list_content .d .ico img {
  width: 45px;
}
.list_content .d .ico div {
  width: 50%;
  float: left;
}
.list_content .d .ico div:nth-child(1) {
  width: 40%;
  float: left;
}
.list_content .d .ico div:nth-child(2) {
  width: 60%;
  text-align: left;
}
.list_content .d .ico font {
  font-weight: bold;
}
.list_content .d .ico .lineH {
  line-height: 40px;
}
/*固定在底部开始*/
/* .aui-footer {
  width: 100%;
  position: relative;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
} */
.gd_dibu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px #f1f1f1 solid;
  padding-top: 5px;
}
.xz {
  width: 33%;
  display: block;
  height: 60px;
  text-align: center;
  font-weight: bold;
  color: #cd2c17;
}
.wxz {
  width: 33%;
  display: block;
  height: 60px;
  text-align: center;
}

.active {
  color: #ff3300;
}
/*固定在底部END*/
/* :root {
  --footer-height: 50px;
}

.main {
  padding-bottom: var(--footer-height);
  overflow-y: auto;
} */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* line-height: var(--footer-height); */
  /* background: #42b983;
  color: #fff; */
  background: #e6e6e6;
  margin: auto;
  margin-bottom: 15%;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  float: left;
}
</style>